<template>
  <div class="admin-feed-block CreatorStatisticsEarnBlock">
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`dollar`"
          :label="`<strong>${$locale['my_earnings']}</strong><div><small>${$locale['commission']} ${statistics.percentage}%</small></div>`"
          :rightDesc="
            `<strong skeleton nowrap gtotal>$ ${number_format(statistics.totalEarnings, 2)}</strong><small skeleton>${$locale['usd']}</small>`
          "
          @click="setQuery({ feed: 'creator-statistics-earnings', startDate, endDate, period: 'custom' })"
          prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["statistics"],
};
</script>

<style lang="scss">
.CreatorStatisticsEarnBlock {
  .LargeButtonLabel strong + div {
    margin-top: $mpadding/2;
  }
}
</style>
